//
//
import { DtsDeclaration, DtsMainForm, DtsWareItem } from '../types/dts.types';

export class SetDtsDocument {
  static readonly type = '[Dts] Set Dts Document';
  constructor(public declaration: DtsDeclaration) {}
}
//
export class DtsUpdateMainForm {
  static readonly type = '[Dts] Update Main Form';
  constructor(public block: DtsMainForm) {}
}

export class DtsReplaceWares {
  static readonly type = '[Dts] Replace Wares';
  constructor(public wareItems: DtsWareItem[]) {}
}

export class DtsAddWareItem {
  static readonly type = '[Dts] Add Ware Item';
  constructor(public wareItem: DtsWareItem) {}
}

export class DtsUpdateWareItems {
  static readonly type = '[Dts] Update Ware Items';
  constructor(public wareItems: DtsWareItem[]) {}
}

export class DtsDeleteWareItems {
  static readonly type = '[Dts] Delete Ware Item';
  constructor(public wareItems: DtsWareItem[]) {}
}
